
.ql-font-sans-serif {
    font-family: Arial, sans-serif;
  }
  
  .ql-font-serif {
    font-family: Georgia, serif;
  }
  
  .ql-font-monospace {
    font-family: 'Courier New', Courier, monospace;
  }
  
  .ql-font-courier-new {
    font-family: 'Courier New', Courier, monospace;
  }
  
  .ql-font-times-new-roman {
    font-family: 'Times New Roman', Times, serif;
  }
  .ql-font-georgia {
    font-family: 'Georgia', Georgia, serif;
  }
  .ql-font-tahoma {
    font-family: 'Tahoma', Tahoma, serif;
  }
  .ql-font-trebuchet-ms {
    font-family: 'Trebuchet MS', 'Trebuchet MS', serif;
  }
  .ql-font-verdana {
    font-family: 'Verdana', 'Verdana', serif;
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="trebuchet-ms"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet-ms"]::before {
    content: 'Trebuchet MS';
    font-family: 'Trebuchet MS';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
    content: 'Verdana';
    content: 'Verdana';
  }
  
  
  
  
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
    content: 'Sans Serif';
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: 'Serif';
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: 'Monospace';
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
    content: 'Courier New';
    font-family: 'Courier New';
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
    content: 'Times';
    font-family: 'Times New Roman';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
    content: 'Georgia';
    font-family: 'Georgia';
  
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
    content: 'Tahoma';
    font-family: 'Tahoma';
  }

/* Toolbar size font size */
.ql-snow .ql-picker.ql-size .ql-picker-label::before{
  font-size: 1em!important;
} 

/* MEDIUM FONT SIZE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="medium"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="medium"]::before {
  content: 'Medium';
  font-size: 1em!important;

}
.ql-editor .ql-size-medium{
  font-size: 1em!important;
}

/* MEDIUM SMALL SIZE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: 'Small';
  font-size: .75em;

}
.ql-editor .ql-size-small{
  font-size: .75em!important;
}
/* LARGE FONT SIZE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: 'Large';
  font-size: 1.5em;

}
.ql-editor .ql-size-large{
  font-size: 1.5em!important;
}

/* XLARGE FONT SIZE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="xlarge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="xlarge"]::before {
  content: 'XLarge';
  font-size: 2em;

}
.ql-editor .ql-size-xlarge{
  font-size: 2em!important;
}


/* XXL STYLE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="xxlarge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="xxlarge"]::before {
  content: 'XXLarge';
  font-size: 2.5em;

}
.ql-editor .ql-size-xxlarge{
  font-size: 2.5em!important;
}


/* HUGE FONT STYLE */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: 'Huge';
  font-size: 3.5em;

}
.ql-editor .ql-size-huge{
  font-size: 3.5em!important;
}



/* 
.quill .ql-toolbar.ql-snow .ql-formats .ql-size.ql-picker .ql-picker-label[aria-hidden="false"].ql-active::before{
  font-size:12px!important;
} */

