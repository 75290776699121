.mobile-banner {
    position: relative;
    text-align: center;
    width: 100%;
  }
  
  .banner-text {
    position: absolute;
    text-align: center;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s ease;
  }
  
  .banner-text.fade-in {
    opacity: 1;
    visibility: visible;
  }
  
  .banner-text.fade-out {
    opacity: 0;
    visibility: hidden;
  }
  
  