


.sidebar {
    transition: width .5s ease-in-out, opacity .5s ease-in-out;

    opacity: 1;
    visibility: visible;
  }
  
  .sidebar.hidden {
    width: 0;
    height:0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: width .5s ease-in-out, opacity .5s ease-in-out;

  }
  
  .main-content {
    transition: width 0.5s ease-in-out;
  }
  
  .sidebar.hidden + .main-content {
    margin-left: 0;
  }

  svg {
      cursor: pointer;
  }


.editorTransition{
  transition: width .5s ease-in-out, opacity .5s ease-in-out;

}
.editorTransition2{
  transition: width .5s ease-in-out, opacity .5s ease-in-out;

}
.editorTransition{
  transition: width .5s ease-in-out, opacity .5s ease-in-out;

}