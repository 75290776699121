body {
  margin: 0;
  /* font-family: CircularXXWeb,Trebuchet,Helvetica,Arial,sans-serif; */ 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding:0;
  /* height:100vh; */
  /* overflow:hidden; */
  color:white;
  /* font-size: 18px; */
  background-color:black;

}

.dark-bg {
  background-color: hsl(240, 2.9%, 6.9%);
}

.footer-margin{
  margin-bottom:5rem;
}

.header-margin{
  margin-top:1rem;
}

.mobile-banner {
  position: relative;
  text-align: center;
  width: 100%;
}

.banner-text {
  position: absolute;
  text-align: center;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 1s ease;
}

.banner-text.fade-in {
  opacity: 1;
  visibility: visible;
}

.banner-text.fade-out {
  opacity: 0;
  visibility: hidden;
}







.custom-accordion-header:hover {
  background-color: rgb(33, 37, 41);
}
.custom-accordion-header {
  background-color: transparent;
}





.landingPageCloudAnimation {
  height: 350px;
  border-radius: 1rem;
}



@media(min-width:767px){
.landingPageCloudAnimation {
  height: 200px;
  border-radius: 10rem;
}
}

.carousel-container {
  width: 100%; /* Full width, or adjust as needed */
  height: 400px; /* Fixed height for the carousel */
  overflow: hidden; /* Prevent content from overflowing */
  position: relative;
  border-radius: 1rem;
  background-color: #f8f9fa; /* Light background for visibility */


}

/* Base styles for carousel item content */
.carousel-item-content {
  width: 100%;
  border-radius: 1rem;
  height: 400px; /* Match the fixed height of the carousel */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: transform 0.8s ease, opacity 0.8s ease; /* Smooth animation */
  transform: translateY(100%); /* Start off-screen below */
  opacity: 0; /* Initially hidden */
}

/* Animation class for upward movement */
.animate-up {
  transform: translateY(0); /* Move content into view */
  opacity: 1; /* Fully visible */
}

/* Optional: Style for Carousel Controls */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1); /* Make controls visible on light background */
}


.map-container {
  opacity: 1;
  visibility: visible;
  height: 100vh; /* Ensure it holds space when visible */
}

.map-container.visible {
  opacity: 1;
  visibility: visible;
}

.map-container.hidden {
  opacity: 1;
  visibility: visible;
  height: 100%;
  overflow:visible;
}

@media (max-width: 991px) { /* Bootstrap lg breakpoint (992px) */
  .map-container {
    transition: opacity 0.5s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;
    height: 100vh; /* Ensure it holds space when visible */
  }

  .map-container.visible {
    opacity: 1;
    visibility: visible;
  }

  .map-container.hidden {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow:hidden;
  }
}


.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1; /* sends the image behind the content */
}


@media(min-width:991px){
.roundedBackground{
  padding: 5em!important;
  border-radius: 3em!important;
  }
}

@media(max-width:990px){
.roundedBackground{
  padding: 2rem!important;
  border-radius: 3rem!important;
  }
}

@media(min-width:990px){
  .belowBanner{
    margin-top:0rem


    
  }
}

@media(max-width:889px){
  .belowBanner{
    margin-top:7rem!important


    
  }
}


@media(min-width:990px){
  .heroSectionV1{
    min-height: 100vh;


    
  }
}

@media(max-width:889px){
  .heroSectionV1{
    height: auto


    
  }
}

@media(min-width:990px){
  .heroSectionV1Header{
    font-size: 4em;
    margin-top:2rem;
  }
}
@media(max-width:989px){
  .heroSectionV1Header{
    font-size: 3em;
    margin-top:1rem;
  }
}

/* Slider.css */



/* Styling the slider thumb (handle) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 30px; /* Thumb width */
  height: 30px; /* Thumb height */
  background: #0d6efd; /* Thumb color */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded corners */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Shadow for better visibility */
    margin-top: -11px; /* Adjust for thumb height */
}

input[type="range"]::-moz-range-thumb {
  width: 30px; /* Thumb width */
  height: 30px; /* Thumb height */
  background: #0d6efd; /* Thumb color */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 50%; /* Rounded corners */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Shadow for better visibility */
    margin-top: -11px; /* Adjust for thumb height */
}

/* Additional styling for the range track */




input[type="range"]::-ms-thumb {
  width: 30px;
  height: 30px;
  background: #4CAF50;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  margin-top: -11px; /* Adjust for thumb height */
}


.learn_more_header .dropdown-menu {
  z-index: 100000000;
}



/* Centering Lines */
.center-line {
  position: absolute;
  background-color: red;
  z-index: 1; /* Ensure lines are below the draggable element */
  pointer-events: none; /* Allow click-through */
}

.page-header{
  font-size: clamp(2.5rem, 2.5rem + 1.75 * (100vw - 23.4375rem) / 66.5625, 5.125rem);
}

/* Horizontal Line */
.center-line.horizontal {
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translateY(-50%);
}

/* Vertical Line */
.center-line.vertical {
  left: 50%;
  top: 0;
  height: 100vh;
  width: 1px;
  transform: translateX(-50%);
}


body.modal-open {
  overflow: hidden;
}
.popover-arrow{
  display: none!important
}


.crowdPostAvatar{
  width:1.75em!important;
  height:1.75em!important;
}


.ql-container {
  font-size: inherit !important;
  font-family: inherit !important;
  background-color:transparent;
  border-bottom-left-radius: .25em;
  border-bottom-right-radius: .25em;
  color:black!important;

  padding: 0px !important;
  border:none!important;

}
/* .description .ql-container {

  font-family: inherit!important


} */

.sidebar-container {
  position: relative;
  height: 100vh;
}


.custom-offcanvas.offcanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px; /* Adjust the width of the sidebar as needed */
  height: 100vh;
  border-right: 1px solid #ddd;
}

.custom-offcanvas .offcanvas-backdrop {
  display: none !important; /* Hide the backdrop */
}

.mapboxgl-popup-content {
  background: black!important;
  padding: 1rem!important;
  /* padding: 0!important; */
  margin:0!important;
  color:white;
}

.mapboxgl-popup-tip {
  border-top-color: black!important;
}

.landingPageCarousel .carousel-inner{
  border-radius: .5em!important;
}

.landingPageCarousel .carousel-inner img{
  border-radius: .5em!important;
}

.landingPageCarousel .carousel-control-prev-icon,
.landingPageCarousel .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.396);
  color:rgb(0, 0, 0);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); /* Add a subtle shadow */
  border-radius: .3rem;
}

.landingPageHeader{
  font-size: 3.5rem;
  letter-spacing: .06em;
}
.landingPageSubHeader{
  font-size: 3rem;
  letter-spacing: .04em;

}


.ql-editor {
  padding:0!important;
  /* min-height:250px; */
  /* max-height: 375px; */
  overflow: "none";
  line-height: normal!important;
}


.ql-toolbar{
  background-color:white;
  border-top-left-radius: .25em;
  border-top-right-radius: .25em;
  /* padding-left:13em!important; */
  /* padding-left:95px!important; */
  z-index: 10000;

}


.description .ql-toolbar{



  padding-left:0px!important;



}


@media (min-width: 520px){
.landingHeading{
  height: 70px !important;;
}
}







.campaignContributionList li{
  margin-top:.5em
}

@media (max-width: 991px){
.drawButton {
  display: none;
}}



@media (min-width: 519px){
.mobileDrawButton {
  display: none;
}}

@media (max-width: 766px){
.campaignDeniedPaddingSmall{
  margin-top:2rem
}
}
@media (max-width: 990px){
.landingPageHeader{
  margin-top:.5em
}
}

@media (min-width: 767px){
.campaignDeniedPaddingSmall{
  margin-top:0rem
}
}

 @media (max-width: 992px) {
.campaign-image {
  height: 170px;
}}
  
@media (min-width: 993px) {
.campaign-image {
  height: 200px;
}}
  

 @media (max-width: 992px) {
.campaignMap {
  height: 50vh;
}

  /* Add more styles as needed */
}

@media (min-width: 993px) {
.campaignMap {
  height: 100vh;

}
}
@media (max-width: 991px) {
.newCampaignMapRow {
  margin-left:.2rem;
  margin-right:.2rem;

}
}

@media (max-width: 993px) {
.newCampaignLayout {
  overflow: 'none'

}
}

@media (min-width: 994px) {
.newCampaignLayout {
  padding-top:1rem;
  padding-bottom:3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--bs-gray-900);
  overflow: scroll;
  height:100vh;
}

  /* Add more styles as needed */
}


.greyed-card {
  background-color: var(--bs-gray-800)
}

.googleButton{
  background-color:'#fff'!important;
}
.googleButton:hover{
  background-color:'red'!important;
}

ul li {
  margin-top: .5em;
}


@media (max-width: 992px) {
.mapContainer {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;

}
}
@media (min-width: 994px) {
.campaignMapRowStyle{
  margin-left: 5px;
  margin-right: -5px;

}
}

.is_active{
  border-bottom: 2px solid var(--bs-success);
  border-radius: 0px;
  padding-left: 1em;
  padding-right: 1em;

}

.hoverItem:hover{
  background-color: var(--bs-gray-800);
  /* background-color: var(--bs-gray-800); */
  border-radius:8px;
  padding-left: 1em;
  padding-right: 1em;
}

.hoverItem{
  margin-left: 3.5px;
  margin-right: 3.5px;
  border-radius:8px;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0px;

}

@media (max-width:991px){
.profileHeader{
  margin-top: 20px;

}
}



.dark-theme-text{
  color:white
}

.truck-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  /* justify-content: space-between; */
  height: 300px; /* Set a fixed height for the containers (adjust as needed) */
}

.canvas-side,
.canvas-back {
  border: 2px dashed black; /* Dotted border style */
  box-sizing: border-box; /* Ensure the border size is included in width/height */
  border-radius: .5rem;

  height: 100vh; /* Fill the container's height */
}

.selected {
  background-color: var(--bs-success);
  /* background-color: rgb(231, 255, 237); */
  border: 2px solid green;
  border-radius: 4px;
  
}

.card-types:hover {
  background-color:var(--bs-success)!important;
  /* background-color: rgb(206, 206, 206)!important; */
  /* background-color: rgb(231, 255, 237)!important; */
  
}


.image-background{
  background-color: rgba(175, 159, 108, 0.4);
  /* background-color: rgba(175, 159, 108, 0.4); */
}

.image-background2{
  background-color: rgba(216, 206, 191, 0.358);
  /* background-color: rgba(216, 206, 191, 0.358); */
}

.form-spacing {
  margin-top:'4rem'
}

.divider-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.divider-line {
  flex-grow: 1;
  border: 1px solid grey;
  margin: 0 10px;
}

.divider-or {
  font-weight: bold;
  text-align: center;
  width: 30px;
}

.Content {
  margin-top: 10px;
}
.border-black {
  border:"4.5px solid rgb(27,81,135)";
  border-top-left-radius: "8px";
  border-top-right-radius: "8px";
  border-bottom-right-radius: "8px";
  border-bottom-left-radius: "8px";
  padding:'2rem';


}



.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

.box-shadow-black {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}

.Sidebar {
  width:140;
  margin:5px;
  margin-left:7px!important;
  position:sticky;
  margin-left: -5px;
  top:62px;
  align-self:flex-start;
  align-items:start;
}

.Sidebar2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050; 
}
@media (min-width: 993px) {
.Sidebar2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  z-index: 1050; 
}}

.MobileSidebar {

  margin:5px;
  position:sticky;
  margin-left: -5px;

  align-self:flex-start;
  align-items:start;
}

.Sidebar .nav-item {
  width: 100%;

  /* top:50%;
  bottom:50%; */
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
}

.Sidebar a {
  /* color: #444; */
  padding-top:10px;
  padding-bottom:10px;
  padding-left:5px;
  
  border-radius: .2rem;


}

.Sidebar a:hover {
  background-color: var(--bs-gray-800)!important;

}

/* .Sidebar a:visited {
  background-color: var(--bs-gray-800)!important;
} */

.Sidebar .nav-item .active, .dropdown-item.active {
  background-color: var(--bs-gray-800)!important;
}

.AboutSidebar {
  width:300px;
  margin:5px;
  position:sticky;
  padding-right:5px;
  /* margin-left: -5px; */
  top:62px;
  align-self:flex-start;
  align-items:start;
  z-index:1;
  border-right: 2px var(--bs-gray-800) dashed ;
  overflow-y: scroll;

  
}

.AboutSidebar .nav-item {
  width: 100%;
  /* top:50%;
  bottom:50%; */
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
}

.AboutSidebar a {
  /* color: #444; */
  padding-top:10px;
  padding-bottom:10px;
  padding-left:5px;
  
  border-radius: .2rem;


}
.gradient-text {
  background: linear-gradient(45deg, #328d55, #30b3a6, #e63a9a, #ff9a9e);
  /* background: linear-gradient(45deg, #8f6566, #30b3a6, #e63a9a, #ff9a9e); */
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.AboutSidebar a:hover {
  background-color: var(--bs-gray-800)!important;

}

/* .Sidebar a:visited {
  background-color: var(--bs-gray-800)!important;
} */

.AboutSidebar .nav-item .active, .dropdown-item.active {
  background-color: var(--bs-gray-800);
  border-right: rgb(58, 111, 255) solid 4px;
}

.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  }


/* .react-date-picker__inputGroup:first-child{
  width:2rem
} */
.react-calendar {
  background-color: var(--bs-gray-800)!important;
  background: var(--bs-gray-800)!important;
}

.react-calendar__tile{
  color:white
}
.react-calendar__tile:hover{
  color:black
}


.react-calendar__tile--now {
  background: var(--bs-gray-900)!important;
}

.react-calendar__month-view__days__day--weekend{
  color:white!important
}


.react-date-picker__inputGroup__month{
  width:3rem!important
}

.react-date-picker__inputGroup__day{
  width:3rem!important
}
.react-date-picker__inputGroup__year{
  width:4.25rem!important
}

/* .react-date-picker__wrapper{
  border-
} */
.react-date-picker__wrapper{
  border:none!important;
  border-bottom: thin solid gray!important
}

.react-date-picker__calendar .react-calendar{
  border-radius: 1rem;
  padding:.5rem;
}

.greyed-border{
  border-top: 1px solid #c8c8c8;
}
.error-font{
  color: "red";
}



.custom-card-col {
  border-radius: .2rem;
  margin:1rem;
  padding:0px!important;
}



.custom-card {
  height: 400px!important;
  position: relative;


}

.custom-card-img {
  width: 100%;
  height: 100vh;
  border:none;
  object-fit: cover;
}

.custom-card-footer{
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left:50%; */
  width: 100%;
  
}

.custom-card-overlay {
  border:none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #1DA1F2;

}

.custom-card-text {

  background: rgb(0, 0, 0);
  border-radius: 2rem;
  background-color: 'black';
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right:.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  color: #ffffff;
  z-index: 10000;
  margin-bottom: .5rem!important;

}
/* style={{ color: '#1DA1F2', borderRadius: '1rem', backgroundColor: 'black', position: "absolute", bottom: 0, right: 0 }} */

.custom-card-button {
  background: #1DA1F2;
  color: black;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .custom-card {
    height: 200px;
  }
  
  .custom-card-overlay {
    font-size: 14px;
    padding: 10px;
  }
  
  .custom-card-button {
    font-size: 12px;
  }
}


.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

}

.resp-sharing-button__icon svg {
  width:100%;
  height: 100vh;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #55acee
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}

.resp-sharing-button--pinterest {
  background-color: #bd081c
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  width:"-webkit-xxx-large";  
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}

.resp-sharing-button--tumblr {
  background-color: #35465C
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c
}

.resp-sharing-button--reddit {
  background-color: #5f99cf
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1
}

.resp-sharing-button--google {
  background-color: #dd4b39
}

.resp-sharing-button--google:hover {
  background-color: #c23321
}

.resp-sharing-button--linkedin {
  background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293
}

/* .resp-sharing-button--email {
  background-color: #777
} */

/* .resp-sharing-button--email:hover {
  background-color: #1DA1F2
} */

.resp-sharing-button--xing {
  background-color: #1a7576
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c
}

.resp-sharing-button--whatsapp {
  background-color: #25D366
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--hackernews {
background-color: #FF6600
}
.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #1DA1F2;
  border-color: #1DA1F2;
}


.scrollable {
  height:100vh;
  overflow:scroll;
  padding-bottom:15rem;
}
