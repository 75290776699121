@media(max-width:989px){
    .pricingHeader{
      font-size: 3.5em;
      font-weight: 400;
  
    }
  }
@media(min-width:990px){
    .pricingHeader{
      font-size: 5em;
      font-weight: 400;
  
    }
  }


@media(max-width:989px){
    .pricingSubHeader{
      font-size: 2.5em;
      font-weight: 400;
  
    }
  }
@media(min-width:990px){
    .pricingSubHeader{
      font-size: 3.5em;
      font-weight: 400;
  
    }
  }



@media(max-width:989px){
    .pricingPageSelect{
      font-weight: 400;
      font-size: 1.3em;
          cursor:'pointer';
  
    }
  }
  
  
  @media(min-width:990px){
      .pricingPageSelect{
        font-size: 1.5em;
        font-weight: 400;
            cursor:'pointer';
    
      }
    }
  @media(max-width:989px){
    .pricingPageForm{
      font-weight: 400;
      font-size: 2em;
  
    }
  }
@media(min-width:990px){
    .pricingPageForm{
      font-size: 2em;
      font-weight: 400;
  
    }
  }


@media(max-width:989px){
    .pricingCardMobile{

      margin-bottom: 1em;
      padding-bottom: 1em;
  
    }
  }
@media(min-width:990px){
    .pricingCardDesktop{
        border-right: 1px solid grey;
  
    }
  }

  .no-border-select {
    border: none;
    color:green;
    box-shadow: none;
    border-radius: 0; /* Ensure no rounded corners */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-bottom: lavender 2px solid;
    background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 5.658A.5.5 0 0 1 2.905 5h10.19a.5.5 0 0 1 .454.658l-4.796 5.482a.5.5 0 0 1-.765 0z"/></svg>') no-repeat right 0.75rem center/2rem 2rem;
    padding-right: 4.5rem;
    cursor:pointer;


  }