.carousel-container {
  position: relative;
}

.play-pause-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  z-index: 10;
}

.play-pause-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.icon {
  color: white;
  font-size: 24px;
  transition: transform 0.3s ease;
}
