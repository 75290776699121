@media(min-width:767px){
    .locationImage img{
      height: 300px; /* Set a maximum width for each image */
      border: 2px solid #ddd; /* Optional: Add a border */
      object-fit: contain;

    }
}

@media(max-width:766px){
    .locationImage img{
      height: 150px; /* Set a maximum width for each image */
      border: 2px solid #ddd; /* Optional: Add a border */
      object-fit: contain;

    }
}


@media(min-width:767px){
    .headerImage {
        max-width: 800px; /* Set a maximum width for each image */
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        flex-shrink: 0; /* Prevent images from shrinking */

    }
}

@media(max-width:766px){
    .headerImage {
        width:100%; /* Set a maximum width for each image */
        height: auto; /* Maintain aspect ratio */
        flex-shrink: 0; /* Prevent images from shrinking */

    }
}




.headerImage{
    border: 1px solid #ddd; /* Optional: Add a border */
    border-radius: 5px; /* Optional: Rounded corners */
    transition: transform 0.3s ease; /* Smooth hover effect */
}

.locationImage img{
    border: 1px solid #ddd; /* Optional: Add a border */
    border-radius: 5px; /* Optional: Rounded corners */
    transition: transform 0.3s ease; /* Smooth hover effect */
}






