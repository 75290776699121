/* Initial hidden state */
.hidden {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }
  
  /* Visible state */
  .visible {
    max-height: 3000px; /* A large enough value to accommodate the content */
    opacity: 1;
    visibility: visible;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }
  