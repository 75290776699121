.description-quill .ql-toolbar {
    color: white!important; /* Set default text color to white */
    padding:4px!important;
    background-color: #212529; /* Optional: set background color for better contrast */
  }
.description-quill .ql-container .ql-editor:before {
    color: white!important; /* Set default text color to white */
    caret-color: white!important; 
    height: 100%;
    pointer-events: auto!important;
  }

.description-quill .ql-toolbar .ql-formats {
    color: white!important; /* Set default text color to white */
    background-color: white!important;
    border-radius: 3px;
    caret-color: white; 

  }


.description-quill .ql-container{
  min-height: 250px;
  color:white!important;
}


  
  /* You can add more styles as needed */
  