@media (min-width: 768px) {
.share-icon{
    display:flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}
}


@media (max-width: 767px) {
.share-icon{
    /* display:grid; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
}

@media (min-width: 768px) {
.share-icon-name{
    margin-left: .5rem;
    font-weight: bold;
}
}


@media (max-width: 767px) {
.share-icon-name{
    margin-top:.5rem;
    margin-left: 0rem;
    font-weight: bold;
}
}
/* .share-icon-name {
    margin-left: .5rem;
    font-weight: bold;
} */