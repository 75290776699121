/* General styles */


/* Frame styles */
.frame {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Plane container with subtle animation */
.plane-container {
  position: relative;
  z-index: 3;
  top:-37%;
  animation: movePlane 3s ease-in-out infinite alternate;
}

.plane {
  width:100px;
}

/* Clouds */
.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

/* Individual cloud animation */
.cloud {
  position: absolute;
  animation: moveClouds linear infinite;
  fill: white;
}

.cloud.big {
  width: 300px;
  height: 150px;
  top: 20%;
  left: 10%;
  animation-duration: 25s;
}

.cloud.distant {
  width: 200px;
  height: 100px;
  top: 40%;
  left: 60%;
  animation-duration: 40s;
}

.cloud.small {
  width: 150px;
  height: 75px;
  top: 70%;
  left: 30%;
  animation-duration: 30s;
}

.cloud.super-slow {
  width: 400px;
  height: 200px;
  top: 10%;
  left: 50%;
  animation-duration: 60s;
}

.cloud.slower {
  width: 250px;
  height: 125px;
  top: 50%;
  left: 20%;
  animation-duration: 35s;
}

/* Cloud animation */
@keyframes moveClouds {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Subtle airplane movement */
@keyframes movePlane {
  from {
    transform: translateY(-5px);
  }
  to {
    transform: translateY(5px);
  }
}
